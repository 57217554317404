<!--
 * @Description: 品牌商家页面组件
 -->
<template>
  <div class="goods">
    <!-- 商家置顶图 -->
    <el-card body-style="padding: 0 0;background: #f9f9f9" class="allCategory">
      <img :src="require('@/assets/imgs/shopBanner.jpg')" style="width: 100%" />
    </el-card>
    <!-- 分类标签END -->

    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>您所在的位置</el-breadcrumb-item>
        <el-breadcrumb-item to="/">绒博汇</el-breadcrumb-item>
        <el-breadcrumb-item>{{ sname }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="totalCount">
        总共<span>{{ total }}</span
        >件商品
      </div>
    </div>
    <div style="clear: both"></div>
    <!-- 面包屑END -->

    <!-- 搜索及分页 -->
    <el-card class="query" body-style="padding: 0 0">
      <el-row class="queryCondition">
        <el-col :span="12" style="height: 40px; line-height: 40px">
          <el-row>
            <el-col :span="4" class="queryTag">浏览量</el-col>
            <el-col :span="4" class="queryTag">下载量</el-col>
            <el-col :span="4" class="queryTag">上新时间</el-col>
            <el-col :span="12" class="queryTag">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" class="pagination">
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="query.pageSize"
            :total="total"
            :current-page="query.page"
            @current-change="currentChange"
          ></el-pagination>
          <!-- 分页END -->
        </el-col>
      </el-row>
    </el-card>
    <!-- 搜索及分页END -->

    <!-- 主要内容区 -->
    <div class="main" v-loading="loading" element-loading-text="加载中">
      <div class="list">
        <GoodsList :list="list" v-if="list.length > 0"></GoodsList>
        <div v-else class="none-product">加载中，请稍候</div>
      </div>
      <!-- 底部分页 -->
      <div style="text-align: center">
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="query.pageSize"
          :total="total"
          :current-page="query.page"
          @current-change="currentChange"
        ></el-pagination>
        <!-- 分页END -->
      </div>
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
import GoodsList from "./../components/GoodsList";

export default {
  name: "Shop",
  components: {
    GoodsList,
  },
  data() {
    return {
      dateRange: "",
      loading: true,
      sname: "商家",
      list: [], // 商品列表
      total: 0, // 商品总量
      currentPage: 1, //当前页码
      query: {
        SID: 0,
        pageSize: 25,
        page: 1,
      },
    };
  },
  created() {
    if (this.$route.query.SID) {
      this.query.SID = this.$route.query.SID;
    }
    this.getData();
  },
  watch: {
    $route(newV) {
      if (newV.query.SID) {
        this.query.SID = newV.query.SID;
        this.currentPage = 1;
        this.page = 1;
        this.getData();
      }
    },
  },
  methods: {
    /**
     * 变更页码
     * @param {*} currentPage
     */
    currentChange(currentPage) {
      this.loading = true;
      this.query.page = currentPage;
      this.getData();
    },
    /**
     * 获取列表数据
     */
    async getData() {
      const { data } = await this.$axios.post(
        this.$newApi + "/api/rbh/goods/list",
        this.query
      );
      this.list = data.data.list;
      this.total = data.data.totalCount;
      if (data.data.list[0]) {
        this.sname = data.data.list[0].SName;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.el-menu--horizontal {
  height: 50px;
  line-height: 50px;
}
.el-input__inner {
  border: 0 !important;
}
.el-menu--horizontal > .el-menu-item {
  margin: 10px 0;
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 15px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff;
  background: #fb4d4f;
  border: 1px solid #fb4d4f;
  border-radius: 1em;
}
.goods {
  background: rgb(246, 246, 246);
  padding: 15px 0;
}
/* 分类标签CSS */
.goods .allCategory {
  width: 1225px;
  margin: 0 auto;
}
/* 分类标签CSS END */

/* 面包屑CSS */
.goods .breadcrumb {
  width: 1225px;
  font-size: 14px;
  color: #e4e4e4;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.goods .breadcrumb .el-breadcrumb {
  height: 40px;
  line-height: 40px;
  width: 1000px;
}
.breadcrumb .totalCount {
  width: 225px;
  color: #000;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  text-align: right;
}
.breadcrumb .totalCount span {
  color: #fb4d4f;
}
/* 面包屑CSS END */

/* 查询 */
.goods .query {
  width: 1225px;
  margin: 0 auto;
}
.query .queryCondition {
  border: 0.5px solid #e4e4e4;
}
.query .queryCondition .queryTag {
  border-right: 1px solid #e4e4e4;
  text-align: center;
  background: #fff;
}
.query .pagination {
  height: 30px;
  line-height: 30px;
  padding: 5px 0;
  text-align: right;
}
/* 查询END */
/* 主要内容区CSS */
.goods .main {
  margin: 0 auto;
  max-width: 1225px;
}
.goods .main .list {
  min-height: 650px;
  padding-top: 14.5px;
  margin-left: -13.7px;
  overflow: auto;
}
.main .list::-webkit-scrollbar {
  width: 0;
  display: none;
}
.goods .main .none-product {
  color: #333;
  margin-left: 13.7px;
}
/* 主要内容区CSS END */
</style>
